// New Form Config Files: config.js, formConfig.js, formArrays.js
// Branding update: index.html

// For setting assets
export const Asset = {
  FormName: "This Product",
  FormUrl: "/form/respiri",
  PaymentFormUrl: "/form/respiri",
};

// Helpers

export const PharmacySelectVariant = {
  // set a hard coded pharmacy
  HardCoded: "HardCoded",
  // allow user to find and set a pharmacy
  Choice: "Choice",
};

export const FeatureName = {
  Payment: "Payment",
  PhotoId: "PhotoId",
  PharmacySelect: "PharmacySelect",
  PatientMedicineSelect: "PatientMedicineSelect",
  BMICalculator: "BMICalulator",
  SMSVerification: "SMSVerification",
  PresetVisitId: "PresetVisitId",
};

// Features Config
export const Feature = {
  [FeatureName.Payment]: {
    enabled: false,
    config: {
      showSecurePaymentGraphic: true,
      supportContact: "admin@belugahealth.com",
      consultationPrice: "$30",
      // paypal?
      paymentType: "Stripe",
    },
  },
  [FeatureName.PhotoId]: {
    enabled: true,
    config: {
      minUpload: 0,
      maxUpload: 5,
    },
  },
  [FeatureName.PharmacySelect]: {
    enabled: false,
    config: {
      // see PharmacySelectVariant
      variant: PharmacySelectVariant.Choice,
      // only set for HardCoded Variant
      hardCodedPharmacy: "",
    },
  },
  // Allow patient to select med
  // if enable, update patientPreferenceOptions
  [FeatureName.PatientMedicineSelect]: {
    enabled: false,
  },
  // BMI calculator, will add 2 questions to ask for height and weight
  // calculate BMI at the end
  [FeatureName.BMICalculator]: {
    enabled: false,
  },
  // Sends SMS to US phone for verification
  [FeatureName.SMSVerification]: {
    enabled: false,
  },
  [FeatureName.PresetVisitId]: {
    enabled: false,
  },
};

/**
 * A Feature config object
 * @typedef {Object} FeatureConfig
 * @property {boolean} enabled - Whether the feature should be enabled or not
 * @property {object} config - Config parameters, varies per feature
 */

/**
 * Get a feature and its configuration
 * @param {keyof FeatureName} name - one of FeatureName
 * @returns FeatureConfig - the config
 */
export const getFeature = (name) => {
  const feat = Feature[name];
  if (!feat) {
    console.error("Feature is not found:", name);
  }
  return feat;
};

export const isFeatureEnabled = (name) => {
  const feat = Feature[name];
  return feat?.enabled;
};

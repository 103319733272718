import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { apiKey } from "../config";
import { questionnaire } from "../DataFiles/formArrays";
import {
  Asset,
  FeatureName,
  getFeature,
  isFeatureEnabled,
} from "../formConfig";
import { phoneVerificationApi } from "./phoneVerificationApi";
import { calculateBMI } from "./bmiUtils";

let formUrl = Asset.FormUrl;

const isPharmacyEnabled = isFeatureEnabled(FeatureName.PharmacySelect);
const hardCodedPharmacy = getFeature(FeatureName.PharmacySelect)?.config
  ?.hardCodedPharmacy;

const isBMICalculatorEnabled = isFeatureEnabled(FeatureName.BMICalculator);
const paymentFeature = getFeature(FeatureName.Payment);
const isPaymentEnabled = paymentFeature.enabled;
const paymentType = paymentFeature.config.paymentType;

const createVisit = async (
  cardInfo,
  formData,
  demographicData,
  setError,
  turnstileToken
) => {
  let stringifiedAnswers = formData;
  stringifiedAnswers = Object.keys(stringifiedAnswers).forEach((k) => {
    if (k === "patientPreference") {
      return;
    }
    if (Array.isArray(stringifiedAnswers[k])) {
      stringifiedAnswers[k] = stringifiedAnswers[k].join(";");
    }
  });
  const {
    allergies,
    medicalConditions,
    selfReportedMeds,
    sex,
    height,
    weight,
    patientPreference,
    visitId,
    ...formRest
  } = formData;
  const { pharmacyId, ...rest } = demographicData;

  let cleanedUpPatientPreference;
  if (patientPreference?.length) {
    cleanedUpPatientPreference = [
      {
        medId: patientPreference[0].medId,
        name: patientPreference[0].name,
        strength: patientPreference[0].strength,
        refills: patientPreference[0].refills,
        quantity: patientPreference[0].quantity,
      },
    ];
  }

  const totalQuestions = questionnaire.length;

  let bmi = {};
  if (isBMICalculatorEnabled) {
    const calculatedBMI = calculateBMI(formData.height, formData.weight);
    bmi = {
      [`Q${totalQuestions}`]: "BMI",
      [`A${totalQuestions}`]: calculatedBMI,
    };
  }

  let cardInfoPayload = {};
  if (isPaymentEnabled) {
    cardInfoPayload = { cardInfo, stripeOrPaypal: paymentType };
    formUrl = Asset.PaymentFormUrl;
  }

  const body = {
    formObj: {
      ...rest,
      ...bmi,
      selfReportedMeds: selfReportedMeds || "None",
      allergies: allergies || "None",
      medicalConditions: medicalConditions || "None",
      patientPreference: cleanedUpPatientPreference || patientPreference,
      ...formRest,
    },
    ...cardInfoPayload,
    company: "respiri",
    visitType: "patientManagement",
    visitId,
    apiKey,
  };

  if (isPharmacyEnabled) {
    const pharmacyIdValue = hardCodedPharmacy || pharmacyId;
    body["pharmacyId"] = pharmacyIdValue;
  }

  try {
    const visitCreationResponse = await API.post("brains", formUrl, {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        "X-Turnstile-Token": turnstileToken,
      },
    });
    if (visitCreationResponse.status !== 200) {
      if (visitCreationResponse.error === "State not valid") {
        setError(
          "Unfortunately we're not able to provide service in your state at this time. Please check back at a later date."
        );
      } else {
        setError(
          "Something went wrong, please check all your info and try again"
        );
      }
      return;
    }

    return visitCreationResponse.data.visitId;
  } catch (err) {
    setError("Something went wrong, please check all your info and try again.");
    return;
  }
};

const submitPdf = async (currentVisitId, pdfsState, setError) => {
  const body = {
    visitId: currentVisitId,
    image: {
      mime: "application/pdf",
      data: pdfsState.pdf,
    },
    apiKey,
  };
  try {
    const pdfResponse = await API.post("brains", "/external/receivePdf", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    if (pdfResponse.status !== 200) {
      setError("Something went wrong, please upload a photo and try again.");
      return;
    }
  } catch (err) {
    setError("Something went wrong, please upload a photo and try again.");
    return;
  }
};

const submitMultiplePhotos = async (
  currentVisitId,
  photosState,
  setError,
  navigate
) => {
  const body = {
    visitId: currentVisitId,
    images: photosState.map((state) => {
      return {
        mime: "image/jpeg",
        data: state.photo,
      };
    }),
    apiKey,
  };
  try {
    const photoResponse = await API.post("brains", "/external/receivePhotos", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    if (photoResponse.status !== 200) {
      setError("Something went wrong, please upload a photo and try again.");
      return;
    }
    navigate("/success");
  } catch (err) {
    setError("Something went wrong, please upload a photo and try again.");
    return;
  }
};

export const routerMap = {
  ...phoneVerificationApi,
  createVisit,
  submitPdf,
  submitMultiplePhotos,
};

// import { Asset } from "../formConfig";

// const formName = Asset.FormName;

export const checkoutText = {
  photoIdHeader: "Please upload the following if you have access to them:",
  header: "PATIENT DEMOGRAPHICS",
  body: "Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  footer: "Please upload the following if you have access to them:",
};

export const introPage = {
  header:
    "The following questions will help our partner physicians determine if you're eligible for medical weight loss management.",
  footer: "Beluga Health is a US licensed telemedicine provider.",
};

export const paymentSuccess = {
  header: "Thank you! Your information was submitted successfully.",
  body: "A doctor will now review your intake form. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.",
  footer: "Beluga Health is a US licensed telemedicine provider.",
};

export const dataSecure = {
  header:
    "Your personal information is securely transmitted via HIPAA compliant transfer to the Beluga Health medical team.",
};

// Ozempic, Saxenda, and Rybelsus at all dosages
export const patientPreferenceOptions = [
  {
    medication: "Ozempic",
    options: [
      {
        name: "Ozempic 0.25mg",
        id: "6TCLeXzE2ZiQ7d2jVdBWBHzrdCOAlJsX",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.25mg",
      },
      {
        name: "Ozempic 0.5mg",
        id: "TUvIbpvehWzmajcpljRtBOlLKOd9Ksfs",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "0.5mg",
      },
      {
        name: "Ozempic 1mg",
        id: "QTjwsz8RFyGZjm0Y912v8nCRrCvmz8sl",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "1mg",
      },
    ],
  },
  {
    medication: "Saxenda",
    options: [
      {
        name: "Saxenda",
        id: "QUYoE53d4nNIxP85Y4XRgsYx9ejcm5Nz",
        quantity: 3,
        dispense: "ml",
        refills: 3,
        strength: "None",
      },
    ],
  },
  {
    medication: "Rybelsus",
    options: [
      {
        name: "Rybelsus 3mg",
        id: "oVJ1ceDCPrKlL8CJdPUekcDSxEAG8Qzo",
        quantity: 30,
        dispense: "tables",
        refills: 0,
        strength: "3mg",
      },
      {
        name: "Rybelsus 7mg",
        id: "vkclzvpo6Ak71P9bocQNgS0N9THeCwif",
        quantity: 30,
        dispense: "tables",
        refills: 0,
        strength: "7mg",
      },
      {
        name: "Rybelsus 14mg",
        id: "rWopjzc2M8TWjQ7wGHA4YEAKpaoVrxHd",
        quantity: 30,
        dispense: "tables",
        refills: 0,
        strength: "14mg",
      },
    ],
  },
];

export const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      if (opt.isRecommended) {
        prev.push({
          answer: {
            displayName: "Let the doctor recommend",
            name: "Let the doctor recommend",
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      } else {
        prev.push({
          answer: {
            name: `${opt.name} ${opt.quantity} ${opt.dispense} - (Refills - ${opt.refills})`,
            displayName: opt.name,
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      }
    });
    return prev;
  },
  []
);

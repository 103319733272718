import { API } from "aws-amplify";
import { apiKey } from "../config";

/**
 * Checks whether the phone number is valid
 *
 * @param {string} phone - the phone number to verify
 * @param {Function} setPhoneError - function to set an error message
 * @return {Promise<boolean>} true if the phone is valid, false otherwise
 */
const verifyPhone = async (phone, setPhoneError) => {
  try {
    const phoneResponse = await API.post("brains", "/external/phoneLookup", {
      credentials: "include",
      body: {
        phone,
        apiKey,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneError("Please enter your cell phone number");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  } catch (err) {
    setPhoneError("Please enter your cell phone number");
    return false;
  }
};

/**
 * Sends phone OTP code for verification.
 *
 * @param {string} phone - The phone number to send verification to (10 digits, it'll assume it's a US phone number)
 * @param {function} setPhoneError - function to set error message
 * @return {Promise<boolean>} Whether the verification was successfully sent or not
 */
const sendPhoneVerification = async (phone, setPhoneError) => {
  try {
    const phoneResponse = await API.post(
      "brains",
      "/external/sendVerification",
      {
        credentials: "include",
        body: {
          phone,
          apiKey,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (phoneResponse.status !== 200) {
      setPhoneError("Unable to send verification code");
      return false;
    } else {
      setPhoneError("");
      return true;
    }
  } catch (err) {
    setPhoneError("Unable to send verification code");
    return false;
  }
};

/**
 * Verify user entered OTP code for phone.
 *
 * @param {string} phone - The phone number to verify OTP for (10 digits, it'll assume it's a US phone number)
 * @param {string} code - The OTP code to verify
 * @param {function} setPhoneCodeError - Function to set error message related to phone code verification
 * @return {Promise<boolean>} Whether the OTP verification was successful
 */
const verifyOTPForPhone = async (phone, code, setPhoneCodeError) => {
  try {
    const phoneResponse = await API.post("brains", "/external/verifyOTP", {
      credentials: "include",
      body: {
        phone,
        code,
        apiKey,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneCodeError("The code you entered is invalid");
      return false;
    } else {
      setPhoneCodeError("");
      return true;
    }
  } catch (err) {
    setPhoneCodeError(
      "There was an error verifying your code. Please try again"
    );
    return false;
  }
};

export const phoneVerificationApi = {
  verifyPhone,
  sendPhoneVerification,
  verifyOTPForPhone,
};

import { FeatureName, isFeatureEnabled } from "../formConfig";
import { patientPreferenceChoices } from "./patientPreferences";

const isBMIEnabled = isFeatureEnabled(FeatureName.BMICalculator);
const BMIQuestions = [
  {
    question: "What is your height?",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "What is your weight?",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
];

export const questionnaire = [
  {
    question: "Case Number",
    answers: ["freeText"],
    type: "freeText",
    standardDemo: "visitId",
    isConditional: false,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements that the patient takes regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Please list what the patient is allergic to and the reaction that each allergy causes.",
    answers: ["allergies"],
    subText:
      "Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.",
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question:
      "Please list the patient's medical conditions and any prior surgeries:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please select the program(s) that the patient will be enrolled in:",
    answers: [
      {
        answer: "RPM",
        showConditional: false,
      },
      {
        answer: "RTM",
        showConditional: false,
      },
      {
        answer: "PCM",
        showConditional: false,
      },
      {
        answer: "TCM",
        showConditional: false,
      },
      {
        answer: "BHI",
        showConditional: false,
      },
      {
        answer: "CCM",
        showConditional: false,
      },
    ],
    standardDemo: "programTypes",
    type: "checkBox",
  },
  {
    question:
      "Primary and secondary qualifying ICD10 diagnosis codes for the selected program",
    answers: ["freeText"],
    standardDemo: "conditions",
    type: "freeText",
    isConditional: false,
  },
  {
    question:
      "What other information do you want us to know about the patient?",
    answers: ["freeText"],
    quickAnswerButtonName: "None",
    type: "freeText",
    isConditional: false,
  },
  {
    question: "Please enter insurance information here if you have it",
    answers: ["freeText"],
    quickAnswerButtonName: "None",
    type: "freeText",
    isConditional: false,
  },
  ...(isBMIEnabled ? BMIQuestions : []),
];

const allowPatientMedicineSelect = isFeatureEnabled(
  FeatureName.PatientMedicineSelect,
);
if (allowPatientMedicineSelect) {
  questionnaire.push({
    question: "Please Select Your preferred medication option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  });
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { routerMap } from "../utils/router";

import "../MainForm/MainForm.css";
import PhotoIdForm from "./PhotoIdForm";
import DemographicForm from "./DemographicForm";
import LoadingModal from "./LoadingModal";
import TurnstileWidget from "../Turnstile/TurnstileWidget";
import { FeatureName, isFeatureEnabled, getFeature } from "../formConfig";

const isPhotoIdEnabled = isFeatureEnabled(FeatureName.PhotoId);
const MIN_UPLOAD_ALLOWED =
  getFeature(FeatureName.PhotoId)?.config?.minUpload || 0;
const MAX_UPLOAD_ALLOWED =
  getFeature(FeatureName.PhotoId)?.config?.maxUpload || 5;

const CheckoutFormContainer = (props) => {
  const { formData, onPageUpdate, currentScreen, headerRef } =
    props;

  const [turnstileToken, setTurnstileToken] = useState("");
  const handleTurnstileToken = (token) => {
    setTurnstileToken(token);
  };

  const [photosState, setPhotosState] = useState([]);
  const [pdfFileState, setPdfileState] = useState([]);
  const [error, setError] = useState("");
  const [isPendingData, setIsPendingData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [demographicData, setDemographicData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    sex: "",
  });
  const [cardInfo, setCardInfo] = useState({
    cardNum: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });

  const { submitMultiplePhotos, submitPdf, createVisit } = routerMap;

  const navigate = useNavigate();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
    return "Are you sure you want to leave this page, your information is still submitting?";
  };

  const handleCreateVisit = async () => {
    try {
      window.addEventListener("beforeunload", handleBeforeUnload);
      setIsPendingData(true);
      const visitId = await createVisit(
        cardInfo,
        formData,
        demographicData,
        setError,
        turnstileToken,
      );

      if (visitId) {
        if (isPhotoIdEnabled) {
          if (photosState.length > 0) {
            await submitMultiplePhotos(visitId, photosState, setError);
          }

          if (pdfFileState.length > 0) {
            for (const pdfItem of pdfFileState) {
              await submitPdf(visitId, pdfItem, setError);
            }
          }
          if (error) {
            console.error("handleSubmitPhotos and Pdfs error", error);
          } else {
            navigate("/success");
          }
        } else {
          navigate("/success");
        }
      }
    } catch (error) {
      console.error("handleCreateVisit error", error);
    }
    setIsPendingData(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };

  const updatePage = () => {
    onPageUpdate("submit");
  };

  const isPhotoScreen = () => {
    return currentScreen === "photo";
  };

  const isSubmitScreen = () => {
    const isSubmit = currentScreen === "submit";
    return isSubmit;
  };

  const totalFilesCount = photosState.length + pdfFileState.length;

  const validFilesCount =
    totalFilesCount >= MIN_UPLOAD_ALLOWED &&
    totalFilesCount < MAX_UPLOAD_ALLOWED;

  const validPhotos =
    isPhotoIdEnabled && photosState.length !== 0
      ? photosState.length && photosState.every((p) => p.photo.length)
      : true;

  const validPdfs =
    isPhotoIdEnabled && pdfFileState.length !== 0
      ? pdfFileState.length && pdfFileState.every((p) => p.pdf.length)
      : true;

  const disableButton = !validFilesCount || !validPhotos || !validPdfs;

  return (
    <div className="checkoutFormContainer">
      {isPhotoScreen() && (
        <PhotoIdForm
          photos={photosState}
          setPhotos={setPhotosState}
          pdfs={pdfFileState}
          setPdfs={setPdfileState}
        />
      )}
      {isSubmitScreen() && (
        <DemographicForm
          headerRef={headerRef}
          formData={formData}
          setValidated={setValidated}
          onFormValidated={(data, cardData) => {
            setDemographicData(data);
            setCardInfo(cardData);
          }}
        />
      )}

      {error && <div className="checkoutError">{error}</div>}
      <div className={`${isPhotoScreen() ? "photo" : ""} ccContainer`}>
        {isSubmitScreen() ? (
          <>
            <TurnstileWidget onTokenChange={handleTurnstileToken} />
            <button
              onClick={handleCreateVisit}
              className="orderButton checkoutButton"
              disabled={disableButton || !validated || isPendingData}
            >
              Submit your information
            </button>
          </>
        ) : (
          <button
            onClick={updatePage}
            className="orderButton checkoutButton"
            disabled={disableButton}
          >
            Continue
          </button>
        )}
      </div>
      <LoadingModal show={isPendingData} />
    </div>
  );
};

export default CheckoutFormContainer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import MainForm from "./MainForm/MainForm";
import SuccessPage from "./SuccessPage/SuccessPage";

import { questionnaire } from "./DataFiles/formArrays";

import "./App.css";

const App = () => {
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(0);

  return (
    <div className="App">
      <Routes>
          <>
            <Route
              path="/"
              element={
                <MainForm
                  formData={formData}
                  setFormData={setFormData}
                  questionnaire={questionnaire}
                  page={page}
                  setPage={setPage}
                />
              }
            />
            <Route path="/success" element={<SuccessPage />} />
          </>
      </Routes>
    </div>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactInputVerificationCode from "react-input-verification-code";

import { routerMap } from "../utils/router";
import { FeatureName, isFeatureEnabled } from "../formConfig";

import "../MainForm/MainForm.css";
import "./PhoneVerificationInput.css";

const VerificationCodePlaceHolder = "·";
const isSMSVerificationEnabled = isFeatureEnabled(FeatureName.SMSVerification);

const PhoneVerificationInput = (props) => {
  const {
    localState,
    setLocalState,
    isPhoneValidated,
    setIsPhoneValidated,
    isPhoneVerified,
    setIsPhoneVerified,
  } = props;

  const [phoneError, setPhoneError] = useState("");
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);

  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(null);
  const [isVerifyingPhone, setIsVerifyingPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState("");
  const [resendOTPCountdown, setResendOTPCountdown] = useState(30);

  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  const { verifyPhone, sendPhoneVerification, verifyOTPForPhone } = routerMap;

  useEffect(() => {
    if (!isSMSVerificationEnabled) {
      return;
    }
    if (
      verifiedPhoneNumber?.length === 10 &&
      localState.phone !== verifiedPhoneNumber
    ) {
      setPhoneError("Please verify your phone number");
      // if they enter a different phone number after, we'll
      // need to have them re-verify their new phone
      setIsPhoneVerified(false);
      return;
    }
  }, [localState.phone, verifiedPhoneNumber]);

  useEffect(() => {
    if (resendOTPCountdown === 0) {
      setResendOTPCountdown(null);
    }

    // exit early when we reach 0
    if (!resendOTPCountdown) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setResendOTPCountdown(resendOTPCountdown - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [resendOTPCountdown]);

  const startOtpCountdown = () => {
    setResendOTPCountdown(30);
  };

  const handleCloseVerificationCodeModal = () =>
    setShowVerificationCodeModal(false);
  const handleShowVerificationCodeModal = async () => {
    const result = await sendPhoneVerification(localState.phone, setPhoneError);
    if (result) {
      setShowVerificationCodeModal(true);
      startOtpCountdown();
    }
  };

  const handleVerifyPhoneCode = async () => {
    if (
      verificationCode.length < 6 ||
      verificationCode.includes(VerificationCodePlaceHolder)
    ) {
      setPhoneCodeError("Please enter a 6 digit verification code");
      return;
    }
    setIsVerifyingPhone(true);
    const result = await verifyOTPForPhone(
      localState.phone,
      verificationCode,
      setPhoneCodeError
    );
    setIsVerifyingPhone(false);
    if (result) {
      setIsPhoneVerified(true);
      setVerifiedPhoneNumber(localState.phone);
      handleCloseVerificationCodeModal();
    } else {
      setIsPhoneVerified(false);
    }
  };

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/(\d{3})(\d{1,4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const validatePhone = async (phone) => {
    setIsValidatingPhone(true);
    setPhoneError("");
    if (phone.length === 10) {
      const result = await verifyPhone(phone, setPhoneError);
      setIsValidatingPhone(false);
      setIsPhoneValidated(result);
      return result;
    } else {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return false;
    }
  };

  const handleChange = async (e) => {
    switch (e.target.name) {
      case "phone":
        e.target.value = phoneMask(e.target.value);
        const cleanedPhone = e.target.value.replace(/\D/g, "");
        if (cleanedPhone.length === 10) {
          if (!isValidatingPhone) {
            const isValidPhone = await validatePhone(cleanedPhone);
            if (isValidPhone) {
              setLocalState((prev) => ({
                ...prev,
                [e.target.name]: cleanedPhone,
              }));
            }
          }
        } else {
          setIsPhoneValidated(false);
        }
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  let phoneVerifyButton = null;
  if (isSMSVerificationEnabled) {
    phoneVerifyButton = isPhoneVerified ? (
      <div className="phoneVerifiedIndication">
        <div className="checkMark"></div>
      </div>
    ) : (
      <button
        disabled={!isPhoneValidated}
        className="phoneVerificationButton orderButton"
        onClick={handleShowVerificationCodeModal}
      >
        Verify
      </button>
    );
  }

  return (
    <>
      <div className="innerSubRow">
        <div className="formFieldSmall checkoutField">Mobile phone number</div>
        <div className="phoneInputVerificationHolder">
          <input
            name="phone"
            placeholder="Phone"
            type="tel"
            onChange={handleChange}
            // maxLength='10'
            className={
              phoneError ? "checkoutInput checkoutInputError" : "checkoutInput"
            }
          />
          {phoneVerifyButton}
        </div>
        {phoneError && <div className="checkoutError">{phoneError}</div>}
      </div>

      <Modal
        id="phoneVerificationModal"
        show={showVerificationCodeModal}
        onHide={handleCloseVerificationCodeModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Verify</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            Please enter the verification code that we sent to your phone
            number.
          </div>
          <div className="custom-styles">
            <ReactInputVerificationCode
              length={6}
              autoFocus={true}
              onChange={(value) => {
                if (value && !value.includes("·")) {
                  setVerificationCode(value);
                  // TODO: do we want to automatically submit?
                  // handleVerifyPhoneCode(value);
                }
              }}
            />
          </div>

          <div className="resendOtpHolder">
            {resendOTPCountdown === null ? (
              <div
                className="resendOtpButton"
                onClick={handleShowVerificationCodeModal}
              >
                Re-send OTP
              </div>
            ) : (
              <div className="resendOtpCountdown">
                Didn't receive a code? Re-send in {resendOTPCountdown}s...
              </div>
            )}
          </div>

          {phoneCodeError && (
            <div className="checkoutError">{phoneCodeError}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="closeVerificationCodeModal"
            disabled={isVerifyingPhone}
            variant="secondary"
            onClick={handleCloseVerificationCodeModal}
          >
            Close
          </Button>
          <Button
            id="confirmVerificationCodeModal"
            disabled={isVerifyingPhone || verificationCode.length < 6}
            variant="primary"
            onClick={handleVerifyPhoneCode}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhoneVerificationInput;

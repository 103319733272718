import React from 'react';

import { paymentSuccess } from '../DataFiles/standardText';

import '../MainForm/MainForm.css';

const SuccessPage = () => {
  return (
    <div className="pageContainer">
      <div className="prevFormCont successPage">
        <div className="successContainer">
          <div className="successLeftColumn">
            <div className="dotsPatternImg"></div>
            <div className="successContent">
              <div className="successHeader">{paymentSuccess.header}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
